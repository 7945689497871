import './App.css';
import {Header} from "./components/Header";
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import images from "./images/missing";
import {v4 as uuidv4} from "uuid";
import {getRandomImage} from "./utils";
import {SideAdPlaceholder} from "./components/adsense";

const Search = ({data}) => {

    let {state} = useLocation();
    state = state["r"];

    useEffect(() => {
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
        // window.adsbygoogle.push({})
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    },[]);

    function results(){
        let previews = [];


        state.forEach( element => {

            let img =  <img className="h-64 rounded-[14px] p-2" key={uuidv4()}  src={getRandomImage()}/>;
            if (element.images !== null) {
                if (element.images.length !== 0) {
                    img = <img className="h-64 rounded-[14px] p-2" key={uuidv4()} src={element.images[0]}/>;
                }
            }

            let link = element.references[0]?.link ?? "fishes/" + element.name.replace(" ", "_");

            previews.push(
                <div className="flex" key={uuidv4()}>
                    <a href={link} className="">
                        {img}
                        <p className="px-2 text-slate-700 text-xl font-light">{element.name}</p></a>
                </div>
            )
        });

        let l = state.length / 8
        for (let i =1; i<l; i++) {
            previews.splice(i * 8, 0,
                <div className="w-[250px]" key={uuidv4()}>
                     <ins className="adsbygoogle"
                     style={{display: "block", textAlign: "center"}}
                     data-ad-layout="in-article"
                     data-ad-format="fluid"
                     data-ad-client="ca-pub-2698258865806441"
                     data-ad-slot="4848653643"/>
                </div>)
        }


        return (
            <div className="flex flex-wrap gap-2 ">
                {state.length > 0 &&
                    previews
                    }
            </div>)
    }


    return (

            <div className="App">
                <Header/>

                <div className="body">

                    {results()}
                </div>

            </div>

        );
    };

export default Search;