import './App.css';
import {ListBox} from './components/ListBox/';
import {Header} from "./components/Header";
import React, {Component} from 'react';
import {Search} from "./components/Search";
import {Gallery} from "./components/Gallery";

export class About extends Component {

    render() {
        return (
            <div className="App ">
            <Header/>



                <div className="body grid grid-cols-2 gap-4">
                    <div className="text-4xl font-extralight col-span-2 pb-12">
                        By fishkeepers, for fishkeepers.
                    </div>

                    <div className="text-lg font-extralight col-span-2 pb-12">
                         Teeny.fish is a site where anyone can share their fishkeeping experience and knowledge to help create a singular
                        site to act as a gateway to rich information across many different sites. The aim is always to
                        give detailed, simple information so that anyone can understand, Teeny.fish isn't meant to be a
                        scientific study site. teeny.fish just stores all of the information in a way that anyone can
                        search and find what they're looking for.


                    </div>

                </div>
            </div>
        );
    }
}

export default About;


