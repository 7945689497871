import './App.css';
import {Header} from "./components/Header";
import React, {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {getRandomImage} from "./utils";
import {API_PATH} from "./config";

const Missing = () => {

    const [fishes, setFishes] = useState([]);

    useEffect(() => {
        let url = API_PATH + "get_fish_by_missing";
        fetch(url)
            .then((resp) => resp.json()) // Transform the data into json
            .then(function (data) {
                setFishes(data)
                console.log(data)
            })
    }, []);



    function results(){
        let previews = [];


        fishes.forEach( element => {

            let img =  <img className="h-64 rounded-[14px] p-2" key={uuidv4()}  src={getRandomImage()}/>;
            if (element.images !== null) {
                if (element.images.length !== 0) {
                    img = <img className="h-64 rounded-[14px] p-2" key={uuidv4()} src={element.images[0]}/>;
                }
            }

            let link = "fishes/" + element.name.replace(" ", "_");

            previews.push(
                <div className="flex" key={uuidv4()}>
                    <a href={link} className="">
                        {img}
                        <p className="px-2 text-slate-700 text-xl font-light">{element.name}</p></a>
                </div>
            )
        });


        return (
            <div className="flex flex-wrap gap-2 ">
                {fishes.length > 0 &&
                    previews
                    }
            </div>)
    }


    return (

            <div className="App">
                <Header/>

                <div className="body">

                    {results()}
                </div>

            </div>

        );
    };

export default Missing;