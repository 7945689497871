import './App.css';
import {ListBox} from './components/ListBox/';
import {Header} from "./components/Header";
import React, {Component} from 'react';
import {Search} from "./components/Search";
import {Gallery} from "./components/Gallery";

export class ToDo extends Component {

    render() {
        return (
            <div className="App ">
            <Header/>



                <div className="body">

                    <div className="text-lg font-extralight col-span-2 pb-12">
                        Teeny.fish is a simple search engine targetted at freshwater fish for the aquarium hobbyist and professional. </div>


                        todo:
                    <div className="text-lg font-light pb-12">
                        ✏️ Get a UX designer to sort out the fish page and edit page <br/>
                        ✏️ Create "missing info" page <br/>
                        ✏️ °C / °F toggle <br/>
                        ✏️ Add UX feedback to image upload <br/>
                        ✏️ Add a user profile page name, something like teeny.fish/user/username<br/>
                        ✏️ Investigate SSR for performance gains <br/>
                        ✏️ Add tests to search functionality <br/>
                        ✏️ Add additional fields to search (fish size, minimum tank size etc) <br/>
                        ✏️ Add guest journals <br/>
                        ✏️ Setup daily backups 😬 <br/>
                        ✏️ Add gallery so that you can view multiple images of fish <br/>

                        ✅ Add toasts so users are aware when changes are saved <br/>
                        ✅ On home page, update the fish lists so that the fishes actual image is used <br/>
                        ✅ Update references block so that references are saved correctly <br/>
                    </div>

                </div>
            </div>
        );
    }
}

export default ToDo;


