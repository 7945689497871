import React, {useState} from 'react';
import { useNavigate, useParams} from "react-router-dom";
import {API_PATH} from "../../config";

import './search.css';

export const Search = ({fish, st}) => {

    const [hardness_min, setHardness_min] = useState(null);
    const [hardness_max, setHardness_max] = useState(null);
    const [temperature_min, setTemperature_min] = useState(null);
    const [temperature_max, setTemperature_max] = useState(null);
    const [ph_min, setPh_min] = useState(null);
    const [ph_max, setPh_max] = useState(null);
    const [distribution, setDistribution] = useState(null);
    const [fish_size, setFish_size] = useState(null);
    const [tank_size, setTank_size] = useState(null);
    const [tank_volume, setTank_volume] = useState(null);
    const [search, setSearch] = useState(null);
    const [extras, setExtras] = useState(null);
    const [error, setError] = useState(null);

    let navigate = useNavigate();

    const handleSubmit = (ev) => {

        ev.preventDefault();

        setError(null);

        let url = API_PATH + "search";

        let body = {
            'name__icontains': search,
            'hardness_min': hardness_min,
            'hardness_max': hardness_max,
            'temperature_min': temperature_min,
            'temperature_max': temperature_max,
            'ph_min': ph_min,
            'ph_max': ph_max,
            'tank_size': tank_size,
            'tank_volume': tank_volume,
            'distribution': distribution,
        };
        const requestOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        };

        fetch(url, requestOptions).then(response => response.json())
            .then(function (data) {

                if (data.length != 0) {
                    if (data["err"]){
                        setError("a minimum of four chars is required")
                    }else {

                        navigate(`/search`, {state: {"r": data}});
                    }
                }else{
                    setError("Unable to find any fish matching your query")
                }

            });
    }


    return (
        <div className="md:col-span-2 pb-12 pt-12">

            <label htmlFor="search" className="mb-2 text-lg text-gray-900 sr-only dark:text-white">Search</label>
            <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none"
                         stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                    </svg>
                </div>

                <input type="search" id="search"
                       className="block w-full p-4 pl-10 text-xl text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                       placeholder="Search by name, pH, temperature, hardness and more 🚀" required onChange={(e) => setSearch(e.target.value)}/>
                <button type="submit"
                        className="text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 text-xl rounded-lg px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={(e) => setExtras(!extras)}>+
                </button>
                <button type="submit"
                        className="text-white absolute right-2.5 mr-12 bottom-2.5 bg-green-500 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 text-xl rounded-lg  px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={(e) => handleSubmit(e)}>Search
                </button>
            </div>

            {error &&
                <div>
                    {error}
                </div>
            }


            {extras &&
                <div className="grid pt-4">

                    <div className="flex flex-wrap gap-4">
                        <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">
                            <div className="flex -mr-px">
                            <span
                                className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                min TDS (ppm)
                            </span>
                            </div>
                            <input type="number"
                                   onChange={(e) => setHardness_min(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">
                            <div className="flex -mr-px">
                            <span
                                className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">max TDS (ppm)</span>
                            </div>
                            <input type="number"
                                   onChange={(e) => setHardness_max(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[160px]">
                            <div className="flex -mr-px">
                            <span
                                className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                min °C
                            </span>
                            </div>
                            <input type="number"
                                   onChange={(e) => setTemperature_min(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[160px]">
                            <div className="flex -mr-px">
                            <span
                                className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                max °C
                            </span>
                            </div>
                            <input type="number"
                                   onChange={(e) => setTemperature_max(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[160px]">
                            <div className="flex -mr-px">
                            <span
                                className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                min pH
                            </span>
                            </div>
                            <input type="number"
                                   onChange={(e) => setPh_min(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[160px]">
                            <div className="flex -mr-px">
                            <span
                                className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                max pH
                            </span>
                            </div>
                            <input type="number"
                                   onChange={(e) => setPh_max(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">
                            <div className="flex -mr-px">
                                <span
                                    className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                    min fish length (cm)
                                </span>
                            </div>
                            <input type="number"
                                   disabled
                                   onChange={(e) => setFish_size(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[240px]">
                            <div className="flex -mr-px">
                                <span
                                    className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                    min tank length (cm)
                                </span>
                            </div>
                            <input type="number"
                                   disabled
                                   onChange={(e) => setTank_size(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[240px]">
                            <div className="flex -mr-px">
                                <span
                                    className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                    max tank length (cm)
                                </span>
                            </div>
                            <input type="number"
                                   disabled
                                   onChange={(e) => setTank_size(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[240px]">
                            <div className="flex -mr-px">
                                <span
                                    className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                    min tank volume (litres)
                                </span>
                            </div>
                            <input type="number"
                                   disabled
                                   onChange={(e) => setTank_volume(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <div className="flex flex-wrap items-stretch mb-4 relative min-w-[240px]">
                            <div className="flex -mr-px">
                                <span
                                    className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                    max tank volume (litres)
                                </span>
                            </div>
                            <input type="number"
                                   disabled
                                   onChange={(e) => setTank_volume(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <div className="flex flex-wrap items-stretch mb-4 relative min-w-[240px]">
                            <div className="flex -mr-px">
                                <span
                                    className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                    country
                                </span>
                            </div>
                            <input type="text"
                                   onChange={(e) => setDistribution(e.target.value)}
                                   className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                        </div>

                        <label className="flex items-center relative w-max cursor-pointer select-none sm_toggle">
                            <input type="checkbox"
                                   disabled
                                   className="sm_toggle appearance-none transition-colors cursor-pointer w-14 h-7 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-green-500"/>
                            <span className="absolute font-medium text-xs uppercase right-1 text-white"> ˚c </span>
                            <span className="absolute font-medium text-xs uppercase right-8 text-white"> ˚f </span>
                            <span
                                className="w-7 h-7 right-7 absolute rounded-full transform transition-transform bg-gray-200"/>
                        </label>

                        <label className="flex items-center relative w-max cursor-pointer select-none lg_toggle">
                            <input type="checkbox"
                                    disabled
                                   className=" lg_toggle appearance-none transition-colors cursor-pointer w-16 h-7 rounded-full
                                   focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-black focus:ring-blue-500 bg-green-500"/>
                            <span className="absolute font-medium text-xs right-1 text-white"> cm </span>
                            <span className="absolute font-medium text-xs right-8 text-white"> inch </span>
                            <span className="w-8 h-7 right-8 absolute rounded-xl transform transition-transform bg-gray-200"/>
                        </label>


                    </div>
                </div>
            }
        </div>
    );
};
