import './App.css';
import React, {Component} from 'react';
import Home from "./home";
import Fish from "./fish";
import Edit from "./edit";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Search from "./search";
import Journals from "./journals";
import About from "./about";
import ToDo from "./todo";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Missing from "./missings";

export default function App() {
  return (
      <div className="p-4">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route exact path="/fishes/*" element={<Fish />}/>
        <Route exact path="/fishes/edit/*" element={<Edit terms={""}/>}/>
        <Route exact path="/search" element={<Search render={(params) => ({ ...params })}/>}/>
        <Route exact path="/missing" element={<Missing/>}/>
        <Route exact path="/journals" element={<Journals/>}/>
        <Route exact path="/todo" element={<ToDo/>}/>
        <Route exact path="/about" element={<About/>}/>

      </Routes>
    </BrowserRouter>

        <ToastContainer />
        </div>
  );
}