import './App.css';
import {Header} from "./components/Header";
import React, {useEffect, useState} from "react";
import {TextArea, Button, Form} from 'semantic-ui-react'
import {ImageUpload} from './components/ImageUpload';
import {API_PATH} from './config';
import {Links} from './components/refs';
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {getRandomImage} from './utils';
const Edit = () => {

    const [selectedImage, setSelectedImage] = useState(undefined);
    const [fishName, setFishName] = useState("");
    const [fishRemarks, setFishRemarks] = useState(null);

    const [hardness_min, setHardness_min] = useState(null);
    const [hardness_max, setHardness_max] = useState(null);
    const [hardness_notes, setHardness_notes] = useState(null);
    const [temperature_min, setTemperature_min] = useState(null);
    const [temperature_max, setTemperature_max] = useState(null);
    const [temperature_notes, setTemperature_notes] = useState(null);
    const [ph_min, setPh_min] = useState(null);
    const [ph_max, setPh_max] = useState(null);
    const [ph_notes, setpH_notes] = useState(null);
    const [distribution, setDistribution] = useState(null);
    const [fish_size, setFish_size] = useState(null);
    const [tank_size, setTank_size] = useState(null);
    const [tank_volume, setTank_volume] = useState(null);

    const [editable, setEditable] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [refs, setRefs] = React.useState([]);


    useEffect(() => {

        let path = window.location.pathname;
        path = path.replace("/fishes/edit/", "").replace("_", " ");
        let url = API_PATH + "get_fish_by_name/" + path;

        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: {
                "Content-Type": "application/json",
            },
        };


        fetch(url, requestOptions)
            .then((resp) => resp.json()) // Transform the data into json
            .then(function (data) {
                setFishName(data["name"])
                setFishRemarks(data["remarks"])
                setHardness_min(data["hardness_min"])
                setHardness_max(data["hardness_max"])
                setHardness_notes(data["hardness_notes"])
                setTemperature_min(data["temperature_min"])
                setTemperature_max(data["temperature_max"])
                setTemperature_notes(data["temperature_notes"])
                setPh_min(data["ph_min"])
                setPh_max(data["ph_max"])
                setpH_notes(data["ph_notes"])
                setDistribution(data["distribution"])
                setFish_size(data["fish_size"])
                setTank_size(data["tank_size"])
                setTank_volume(data["tank_volume"])
                setSelectedImage(data["images"][0]?.img_url ?? undefined)

            })
    }, []);

    const handleClick = () => {
        setOpen(!open);
    };
    const handleSetRefs = (newRefs) => {
        setRefs(newRefs);
    };

     function submit() {

        let path = window.location.pathname;
        path = path.replace("/fishes/edit/", "").replace("_", " ");
        let url = API_PATH + "update/" + path;

        // Simple PUT request with a JSON body using fetch
        const element = document.querySelector('#put-request .date-updated');

        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                "name": fishName,
                "hardness_min": hardness_min,
                "hardness_max": hardness_max,
                "hardness_notes": hardness_notes,
                "temperature_min": temperature_min,
                "temperature_max": temperature_max,
                "temperature_notes": temperature_notes,
                "ph_min": ph_min,
                "ph_max": ph_max,
                "ph_notes": ph_notes,
                "distribution": distribution,
                "fish_size": fish_size,
                "tank_size": tank_size,
                "tank_volume": tank_volume,
                "remarks": fishRemarks,
                "references": refs,
            })
        };

      //   try {
      //   const response = await fetch(url, requestOptions)
      //
      //   const result = await response.json();
      //
      //
      //   toast.success('Content saved', {
      //           position: "bottom-right",
      //           autoClose: 5000,
      //           hideProgressBar: false,
      //           closeOnClick: true,
      //           pauseOnHover: false,
      //           draggable: false,
      //           progress: undefined,
      //           theme: "colored",
      //       });
      //
      //   console.log("Success:", result);
      // } catch (error) {
      //   console.error("Error:", error);
      // }


        fetch(url, requestOptions)
            .then(response => response.json()).then(
            toast.success('Content saved', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
            })).catch(error =>
            toast.error(error, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "colored",
            }))
    }

    const img = selectedImage !== undefined ? <img className="fish_img" src={selectedImage}/> : <img src={getRandomImage()}/>;


    return (

        <div className="App">
            {!open &&
                <Header/>
            }

            {open &&
                <ImageUpload fish={fishName} st={handleClick}/>
            }

            {!open &&

                <div className="fish_main">

                    <div className="text-3xl p-2 flex">
                        <div className="p-2">{fishName}</div>
                        <Link onClick={(e) => setOpen(true)} className="text-sm">upload</Link>
                    </div>

                    <div className="fish_info">
                        {img}
                    </div>


                    <Form>
                        <div className="mb-4 border border-solid border-slate-200 rounded pb-4 px-4">
                            <label className="fish_info">
                                <div className="text-xl">General fish advice</div>
                                <p>Niceties, things people should know, behaviour, etc.</p>
                                <textarea className="fish_text_area"
                                          onChange={(e) => setFishRemarks(e.target.value)}
                                />
                            </label>
                        </div>

                        <div className="mb-8 border border-solid border-slate-200 rounded p-4">

                            <div className="text-xl flex">Water Hardness</div>
                            <p>as ppm</p>
                            <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">
                                <div className="flex -mr-px">


                                        <span
                                            className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                            min TDS (ppm)
                                        </span>
                                </div>

                                <input type="number"
                                       onChange={(e) => setHardness_min(e.target.value)}
                                       value={hardness_min}
                                       className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                                <div className="flex -mr-px">


                                    {/*<span className="flex items-center leading-normal  rounded rounded-r-none  px-3 whitespace-no-wrap text-grey-dark  font-medium">*/}
                                    {/*    <img src={edit_field} className="h-8"/>*/}
                                    {/*</span>*/}
                                </div>


                            </div>


                            <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">

                                <div className="flex -mr-px">


                                        <span
                                            className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                            max TDS (ppm)
                                        </span>
                                </div>

                                <input type="number"
                                       onChange={(e) => setHardness_max(e.target.value)}
                                       value={hardness_max}
                                       className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                                <div className="flex -mr-px">


                                    {/*<span className="flex items-center leading-normal  rounded rounded-r-none  px-3 whitespace-no-wrap text-grey-dark  font-medium">*/}
                                    {/*    <img src={edit_field} className="h-8"/>*/}
                                    {/*</span>*/}
                                </div>


                            </div>

                            <label className="fish_info fish_tank_info fish_label fish_distribution">
                                <h3>Hardness notes</h3>
                                {/*<div className="fish_text_area fish_distribution">{distribution}</div>*/}

                                <TextArea className="fish_text_area fish_distribution"
                                          disabled={editable} value={hardness_notes}
                                          onChange={(e) => setHardness_notes(e.target.value)}/>

                            </label>
                        </div>

                        <div className="mb-8 border border-solid border-slate-200 rounded p-4">
                            <div className="text-xl flex">Temperature</div>
                            <p>as ºC, until ºF conversion is implemented.</p>
                            <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">
                                <div className="flex -mr-px">
                                    <span
                                        className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                        min temperature ºC
                                    </span>
                                </div>

                                <input type="number"
                                       onChange={(e) => setTemperature_min(e.target.value)}
                                       value={temperature_min}
                                       className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                                <div className="flex -mr-px">


                                    {/*<span className="flex items-center leading-normal  rounded rounded-r-none  px-3 whitespace-no-wrap text-grey-dark  font-medium">*/}
                                    {/*    <img src={edit_field} className="h-8"/>*/}
                                    {/*</span>*/}
                                </div>
                            </div>


                            <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">

                                <div className="flex -mr-px">

                                    <span
                                        className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                        max temperature ºC
                                    </span>
                                </div>

                                <input type="number"
                                       onChange={(e) => setTemperature_max(e.target.value)}
                                       value={temperature_max}
                                       className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                                <div className="flex -mr-px">


                                    {/*<span className="flex items-center leading-normal  rounded rounded-r-none  px-3 whitespace-no-wrap text-grey-dark  font-medium">*/}
                                    {/*    <img src={edit_field} className="h-8"/>*/}
                                    {/*</span>*/}
                                </div>


                            </div>

                            <label className="fish_info fish_tank_info fish_label fish_distribution">
                                <h3>Temperature notes</h3>
                                {/*<div className="fish_text_area fish_distribution">{distribution}</div>*/}

                                <TextArea className="fish_text_area fish_distribution"
                                          disabled={editable} value={temperature_notes}
                                          onChange={(e) => setTemperature_notes(e.target.value)}/>

                            </label>
                        </div>

                        <div className="mb-8 border border-solid border-slate-200 rounded p-4">
                            <div className="text-xl flex">pH</div>
                            <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">
                                <div className="flex -mr-px">
                                    <span
                                        className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                        min pH
                                    </span>
                                </div>

                                <input type="number"
                                       onChange={(e) => setPh_min(e.target.value)}
                                       value={ph_min}
                                       className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                                <div className="flex -mr-px">


                                    {/*<span className="flex items-center leading-normal  rounded rounded-r-none  px-3 whitespace-no-wrap text-grey-dark  font-medium">*/}
                                    {/*    <img src={edit_field} className="h-8"/>*/}
                                    {/*</span>*/}
                                </div>
                            </div>


                            <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">

                                <div className="flex -mr-px">

                                    <span
                                        className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                        max pH
                                    </span>
                                </div>

                                <input type="number"
                                       onChange={(e) => setPh_max(e.target.value)}
                                       value={ph_max}
                                       className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                                <div className="flex -mr-px">


                                    {/*<span className="flex items-center leading-normal  rounded rounded-r-none  px-3 whitespace-no-wrap text-grey-dark  font-medium">*/}
                                    {/*    <img src={edit_field} className="h-8"/>*/}
                                    {/*</span>*/}
                                </div>


                            </div>

                            <label className="fish_info fish_tank_info fish_label fish_distribution">
                                <h3>pH notes</h3>
                                {/*<div className="fish_text_area fish_distribution">{distribution}</div>*/}

                                <TextArea className="fish_text_area fish_distribution"
                                          disabled={editable} value={ph_notes}
                                          onChange={(e) => setpH_notes(e.target.value)}/>

                            </label>

                        </div>

                        <div className="mb-8 border border-solid border-slate-200 rounded p-4">
                            <label className="fish_info fish_tank_info fish_label fish_distribution">
                                <div className="text-xl flex">Distribution</div>
                                <p></p>
                                {/*<div className="fish_text_area fish_distribution">{distribution}</div>*/}

                                <TextArea className="fish_text_area fish_distribution"
                                          disabled={editable} value={distribution}
                                          onChange={(e) => setDistribution(e.target.value)}/>

                            </label>
                        </div>

                        <div className="mb-8 border border-solid border-slate-200 rounded p-4">


                            <div className="text-xl flex">Fish size</div>
                            <p>in cm</p>
                            <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">
                                <div className="flex -mr-px">
                                    <span
                                        className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                        min fish length
                                    </span>
                                </div>

                                <input type="number"
                                       onChange={(e) => setFish_size(e.target.value)}
                                       value={fish_size}
                                       className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                                <div className="flex -mr-px">


                                    {/*<span className="flex items-center leading-normal  rounded rounded-r-none  px-3 whitespace-no-wrap text-grey-dark  font-medium">*/}
                                    {/*    <img src={edit_field} className="h-8"/>*/}
                                    {/*</span>*/}
                                </div>
                            </div>


                            <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">

                                <div className="flex -mr-px">

                                    <span
                                        className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                        max fish length
                                    </span>
                                </div>

                                <input type="number"
                                       onChange={(e) => setFish_size(e.target.value)}
                                       value={fish_size}
                                       className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                                <div className="flex -mr-px">


                                    {/*<span className="flex items-center leading-normal  rounded rounded-r-none  px-3 whitespace-no-wrap text-grey-dark  font-medium">*/}
                                    {/*    <img src={edit_field} className="h-8"/>*/}
                                    {/*</span>*/}
                                </div>


                            </div>

                        </div>

                        <div className="mb-4 border border-solid border-slate-200 rounded p-4">
                            <div className="text-xl flex">Aquarium setup</div>
                            <p>as ppm</p>
                            <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">
                                <div className="flex -mr-px">

                                        <span
                                            className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                            Minimum length
                                        </span>
                                </div>

                                <input type="number"
                                       onChange={(e) => setTank_size(e.target.value)}
                                       value={tank_size}
                                       className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>
                                <div className="flex -mr-px">


                                    {/*<span className="flex items-center leading-normal  rounded rounded-r-none  px-3 whitespace-no-wrap text-grey-dark  font-medium">*/}
                                    {/*    <img src={edit_field} className="h-8"/>*/}
                                    {/*</span>*/}
                                </div>


                            </div>


                            <div className="flex flex-wrap items-stretch grow mb-4 relative min-w-[190px]">

                                <div className="flex -mr-px">

                                        <span
                                            className="flex items-center leading-normal bg-grey-lighter rounded rounded-r-none border border-r-0 border-grey-light px-3 whitespace-no-wrap text-grey-dark bg-gray-300 font-medium">
                                            minimum tank volume
                                        </span>
                                </div>

                                <input type="number"
                                       onChange={(e) => setTank_volume(e.target.value)}
                                       value={tank_volume}
                                       className="flex-shrink flex-grow flex-auto leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"/>


                            </div>


                        </div>

                        <Links setRefs={handleSetRefs}/>

                        <Button onClick={submit} className="ui teal float-right">submit</Button>


                    </Form>

                </div>
            }


        </div>

    );
};

export default Edit;