import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";

const link = {
    title: "",
    link: ""
}

export const Links = ({setRefs}) => {

    const [formInputs, setFormInputs] = useState([{title: '', link: ''}]);

    const handleInputChange = (index, event) => {
        const {name, value} = event.target;
        const newFormInputs = [...formInputs];
        newFormInputs[index][name] = value;
        setFormInputs(newFormInputs);
        setRefs(newFormInputs);
    };

    const handleAddRow = () => {
        setFormInputs([...formInputs, {title: '', link: ''}]);
    };

    const handleRemoveRow = (index) => {
        const newFormInputs = [...formInputs];
        newFormInputs.splice(index, 1);
        setFormInputs(newFormInputs);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Process the form inputs as needed
        console.log(formInputs);
    };

    const [refs] = useState([]);

    const [rows, setRows] = useState([]);

    let navigate = useNavigate();

    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // add_row(undefined, undefined)
        if (refs !== []) {
            refs.forEach(element => {
                add_row(element.title, element.link)
            });
        }
    });


    function add_row(title, link) {

        let r =

            <form onSubmit={handleSubmit}>
                <div className="flex">
                    <input type="input" id="title"
                           className="block flex-auto m-2 leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"
                           placeholder="seriouslyfish"
                           value={title} required/>
                    <input type="input" id="title"
                           className="block flex-auto p-2 m-2 leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative  text-xl"
                           placeholder="seriouslyfish.com/ssss"
                           value={link} required/>
                    <input type="submit" value="Submit"/>
                </div>
            </form>

        setRows([...rows, r]);

        return (rows);
    }


    // return (
    //     <div className="mb-4 border border-solid border-slate-200 rounded p-4">
    //
    //         <div className="flex">
    //             <p className="block flex-auto m-2 leading-normal w-px flex-1 rounded-l-none px-3 relative text-xl">name</p>
    //             <p className="block flex-auto m-2 leading-normal w-px flex-1 rounded-l-none px-3 relative text-xl">url</p>
    //         </div>
    //         {rows}
    //         <div className="flex">
    //             <button type="button"
    //                     onClick={() => add_row(undefined, undefined)}
    //                     className="text-white right ml-auto m-[5px] bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 text-xl rounded-lg px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">+
    //             </button>
    //         </div>
    //     </div>
    // );

    return (
        <div className=" mb-2 border border-solid border-slate-200 rounded p-4">
            <p className="block flex-auto leading-normal w-px flex-1 rounded-l-none px-3 relative text-xl">References</p>
          <div className="flex">
                 <p className="block flex-auto leading-normal w-px flex-1 rounded-l-none px-3 relative text-xl">name</p>
                 <p className="block flex-auto leading-normal w-px flex-1 rounded-l-none px-3 relative text-xl">url</p>
             </div>
            <form onSubmit={handleSubmit}>
                {formInputs.map((formInput, index) => (
                    <div key={index} className="flex gap-2 mb-2">
                        <input
                            type="text"
                            className="block flex-auto m-2 leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative text-xl"
                            placeholder="seriouslyfish"
                            name="title"
                            value={formInput.title}
                            onChange={(event) => handleInputChange(index, event)}
                            required
                        />
                        <input
                            type="text"
                            className="block flex-auto p-2 m-2 leading-normal w-px flex-1 border h-10 border-grey-light rounded rounded-l-none px-3 relative text-xl"
                            placeholder="seriouslyfish.com/ssss"
                            name="link"
                            value={formInput.link}
                            onChange={(event) => handleInputChange(index, event)}
                            required
                        />
                        <button type="button" onClick={() => handleRemoveRow(index)}
                        className="text-white right ml-auto  bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 text-xl rounded-lg  dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800 h-10  w-10">
                            x
                        </button>

                    </div>
                ))}
                {/*<input type="submit" value="Submit" />*/}
            </form>

            <div className="flex">
                <button type="button"
                        onClick={handleAddRow}
                        className="text-white right ml-auto mt-2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 text-xl rounded-lg dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 h-10  w-10">+
                </button>
            </div>

        </div>
    );
};

