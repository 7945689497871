import React, {useState}from 'react';
import Tetra from '../../images/tetra.jpeg';
import { v4 as uuidv4 } from 'uuid';
import {API_PATH} from "../../config";

export const ImageUpload = ({fish, st}) =>{

    const [images, setImages] = useState([]);
    const [email, setEmail] = useState("j@c.com");
    const [link, setLink] = useState("www.google.com");
    const [first, setFirst] = useState("l");
    const [last, setLast] = useState("m");
    const [dragging, setDrag] = useState(false);
    const [description, setDescription] = useState("n");

    const handleDrop = function(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
          [...e.dataTransfer.files].forEach(file => {
              images.push({image: file, "name": file.name})
          });
          setImages([...images]);
      }

      setDrag(false);
    };

    const handleImageUpload = (e) => {

        [...e.target.files].forEach(file => {

                 images.push({image: file, "name": file.name})

            setImages([...images]);
            })



    }
    
    const handleSubmit = (ev) => {
        ev.preventDefault();

        let formData = new FormData();

        formData.append("title", "");
        formData.append("author_firstname", first);
        formData.append("author_lastname", last);
        formData.append("author_email", email);
        formData.append("author_link", link);
        formData.append("description", description);

        [...images].forEach(img => {
            formData.append(img.name, img.image, img.name);
        });


        let path = window.location.pathname;
        path = path.replace("/fishes/", "").replace("_", " ").replace("edit/", "");
        let url = API_PATH + "upload/image/" + path;

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        fetch(url, requestOptions).then(response => {console.log(response.json())}).catch(console.error);
    }

    function preview(){
        let previews = [];

        images.forEach( element => {
            previews.push(<img className="h-16" key={uuidv4()} src={URL.createObjectURL(element.image)}/>)
        });

        return (
            <div className="flex flex-wrap gap-2 ">
                {images.length > 0 &&
                    previews
                    }
            </div>)
    }

    const onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setDrag(true);
        }
        return (
            <div className=" z-10 top-0 right-0 bottom-0 left-0 min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 bg-gray-500 bg-no-repeat bg-cover items-center h-full">
                        <div className="absolute h-full inset-0 z-0" onClick={() => st()}></div>
                        <div className="sm:max-w-lg w-full p-10 bg-white rounded-xl z-10">
                            <div className="text-center">
                                <h2 className="mt-5 text-3xl font-100 text-gray-900">
                                    Upload images of {fish}
                                </h2>

                            </div>
                            <form className="mt-8 space-y-3"
                                  onSubmit={(e) =>handleSubmit(e)}>
                                <div className="grid grid-cols-1 space-y-2">
                                    <label className="text-sm font-bold text-gray-500 tracking-wide">Attach Document</label>
                                    <div className="flex items-center justify-center w-full"
                                         onDrop={(e) => handleDrop(e)}
                                         onDragOver={(e) => onDragOver(e)}
                                    >
                                        <label
                                            className={"w-full flex flex-col rounded-lg border-4 border-dashed w-full h-60 p-10 group text-center " + (dragging ? 'border-green-700' : '')}>
                                            <div
                                                className="h-full w-full text-center flex flex-col items-center justify-center items-center  ">
                                                <div className="flex flex-auto max-h-48 w-2/5 mx-auto -mt-10">
                                                    <img className="has-mask h-36 object-center"
                                                         src={Tetra}
                                                         alt="freepik image"/>
                                                </div>
                                                <p className="pointer-none text-gray-500 "><span className="text-sm">Click, or drag and drop</span> files
                                                    here</p>
                                            </div>
                                            <input type="file" className="hidden" multiple onChange={(e) => handleImageUpload(e)}/>
                                        </label>
                                    </div>
                                </div>
                                <p className="text-sm text-gray-300">
                                    <span>File type: png and jpg only.</span>
                                </p>

                                {preview()}

                                <h4>Attribution</h4>

                                <div className="grid grid-cols-1 space-y-2">
                                    <label className="text-sm font-bold text-gray-500 tracking-wide">Email</label>
                                    <input className="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="" placeholder="mail@gmail.com" onChange={(e) => setEmail(e.target.value)}/>
                                    <p className="text-slate-500">Sadly, we must ask for some identification when hosting images on this website.</p>
                                </div>


                                <div className="grid grid-cols-1 space-y-2">
                                    <label className="text-sm font-bold text-gray-500 tracking-wide">Author link</label>
                                    <input className="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="" placeholder="mail@gmail.com" onChange={(e) => setLink(e.target.value)}/>
                                    <p className="text-slate-500">Optionally your Instagram, or source of file etc.</p>
                                </div>
                                <div className="grid grid-cols-1 space-y-2">
                                    <label className="text-sm font-bold text-gray-500 tracking-wide">Fisrt Name</label>
                                    <input className="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="" placeholder="Thomas" onChange={(e) => setFirst(e.target.value)}/>
                                </div>

                                <div className="grid grid-cols-1 space-y-2">
                                    <label className="text-sm font-bold text-gray-500 tracking-wide">Last Name</label>
                                    <input className="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="" placeholder="Smith" onChange={(e) => setLast(e.target.value)}/>
                                </div>

                                <div className="grid grid-cols-1 space-y-2">
                                    <label className="text-sm font-bold text-gray-500 tracking-wide">Description</label>
                                    <input className="text-base p-2 border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500" type="" placeholder="Picture taken from my fishroom." onChange={(e) => setDescription(e.target.value)}/>
                                </div>

                                <div>
                                    <button type="submit" className="my-5 w-full flex justify-center bg-blue-500 text-gray-100 p-4  rounded-full tracking-wide
                                    font-semibold  focus:outline-none focus:shadow-outline hover:bg-blue-600 shadow-lg cursor-pointer transition ease-in duration-300">
                                        Upload
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
        );
};
