import a from "./dalle-fish Medium.jpeg";
import b from "./DALL·E - a fish in a one line drawing 1 Medium.jpeg";
import c from "./DALL·E - a fish in a one line drawing 2 Medium.jpeg";
import d from "./DALL·E - a fish in a one line drawing 3 Medium.jpeg";
import e from "./DALL·E - a fish in a one line drawing 4 Medium.jpeg";
import f from "./DALL·E - a fish in a one line drawing 5 Medium.jpeg";
import g from "./DALL·E - a fish in a one line drawing 6 Medium.jpeg";
import h from "./DALL·E - a fish in a one line drawing 7 Medium.jpeg";
import i from "./DALL·E - a fish in a one line drawing 8 Medium.jpeg";
import j from "./DALL·E - a fish in a one line drawing 9 Medium.jpeg";
import k from "./DALL·E - a fish in a one line drawing 10 Medium.jpeg";
import l from "./DALL·E - a fish in a one line drawing 11 Medium.jpeg";
import m from "./DALL·E - a fish in a one line drawing 12 Medium.jpeg";

const images = {
    a,
    b,
    c, d, e,f,g,h,i,j,k,l,m
}





export default images