import './App.css';
import {Header} from "./components/Header";

import React, {useEffect, useRef, useState} from "react";
import {Input, Form} from 'semantic-ui-react'
import {getRandomImage} from "./utils";
import {API_PATH} from './config';
import {Link} from "react-router-dom";

const Fish = () => {

    const [selectedImage, setSelectedImage] = useState("");
    const [fishName, setFishName] = useState("");
    const [fishRemarks, setFishRemarks] = useState(undefined);


    const [hardness_min, setHardness_min] = useState(undefined);
    const [hardness_max, setHardness_max] = useState(undefined);
    const [temperature_min, setTemperature_min] = useState(undefined);
    const [temperature_max, setTemperature_max] = useState(undefined);
    const [ph_min, setPh_min] = useState(undefined);
    const [ph_max, setPh_max] = useState(undefined);
    const [distribution, setDistribution] = useState("");
    const [fish_size, setFish_size] = useState(undefined);
    const [tank_size, setTank_size] = useState(undefined);
    const [tank_volume, setTank_volume] = useState(null);
    const [refs, setRefs] = useState([]);


const textareaRef = useRef(null);
    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        let path = window.location.pathname;
        path = path.replace("/fishes/", "").replace("_", " ");
        let url = API_PATH + "get_fish_by_name/" + path;
        fetch(url)
            .then((resp) => resp.json()) // Transform the data into json
            .then(function (data) {
                console.log(data)
                setFishName(data["name"])
                setFishRemarks(data["remarks"])
                setHardness_min(data["hardness_min"])
                setHardness_max(data["hardness_max"])
                setTemperature_min(data["temperature_min"])
                setTemperature_max(data["temperature_max"])
                setPh_min(data["ph_min"])
                setPh_max(data["ph_max"])
                setDistribution(data["distribution"])
                setFish_size(data["fish_size"])
                setTank_size(data["tank_size"])
                setTank_volume(data["tank_volume"])
                setRefs(data["references"])
                setSelectedImage(data["images"][0]?.img_url)
            })
    }, []);

    const handleClick = () => {
        setOpen(!open);
    };

    const links = () => {

        if (refs === undefined){
            return (<div />)
        }

        return (
            <div className=" mb-2 border border-solid border-slate-200 rounded p-4">
          <div className="flex">
                 <p className="block flex-auto leading-normal w-px flex-1 rounded-l-none px-3 relative text-sm">name</p>
                 <p className="block flex-auto leading-normal w-px flex-1 rounded-l-none px-3 relative text-sm">url</p>
             </div>
                {refs.map((refsObject, index) => (
                    <div key={index} className="flex gap-2 mb-2">
                        <div className="block flex-auto leading-normal w-px flex-1 rounded-l-none px-3 relative">{refsObject.title}</div>

                        <a href={refsObject.link} className="block flex-auto leading-normal w-px flex-1 rounded-l-none px-3 relative">{refsObject.link}</a>

                    </div>
                ))}

        </div>
        )
    }

    const img = selectedImage !== undefined ? <img className="fish_img" src={selectedImage}/> : <img src={getRandomImage()}/>;


    return (

        <div className="App">

                <Header/>
                <div className="fish_main">

                    <div className="text-3xl p-2 flex">
                        <div className="p-2">{fishName}</div>
                        <Link to={"edit/" + fishName.replace(" ", "_")} className="p-2 text-sm">edit</Link>
                    </div>

                    <div className="fish_info">
                        {img}
                    </div>


                    <Form>
                        <label className="fish_info">
                            <p className="fish_text_area">{fishRemarks}</p>
                        </label>


                        <label className="fish_info fish_tank_info fish_label fish_hardness">
                            <h3>Hardness</h3>

                            <Input className="small-input" label='min TDS (ppm)'
                                   labelPosition='right' label-width="50px" value={hardness_min ?? 0}
                                   onChange={(e) => setHardness_min(e.target.value)}/>
                            <Input className="ml-x small-input" label='max TDS (ppm)'
                                   labelPosition='right' label-width="50px" value={hardness_max ?? 0}
                                   onChange={(e) => setHardness_max(e.target.value)}/>


                        </label>


                        <label className="fish_info fish_tank_info fish_label fish_hardness fish_temperature">
                            <h3>Temperature</h3>

                            <Input className="small-input" label='min °C'  labelPosition='right'
                                   value={temperature_min ?? 0}
                                   onChange={(e) => setTemperature_min(e.target.value)}/>
                            <Input className="ml-s small-input" label='max °C'
                                   labelPosition='right' value={temperature_max ?? 0}
                                   onChange={(e) => setTemperature_max(e.target.value)}/>
                        </label>


                        <label className="fish_info fish_tank_info fish_label fish_hardness fish_ph">
                            <h3>pH</h3>
                            <Input className="small-input" label='min' labelPosition='right'
                                   value={ph_min ?? 0} onChange={(e) => setPh_min(e.target.value)}/>
                            <Input className="ml-s small-input" label='max'
                                   labelPosition='right' value={ph_max ?? 0}
                                   onChange={(e) => setPh_max(e.target.value)}/>
                        </label>


                        <label className="fish_info fish_tank_info fish_label fish_distribution">
                            <h3>Distribution</h3>
                            {/*<div className="fish_text_area fish_distribution">{distribution}</div>*/}

                            <textarea className="fish_text_area fish_distribution"
                                      value={distribution ?? ""}
                                      onChange={(e) => setDistribution(e.target.value)}
                                        ref={textareaRef}
                            />

                        </label>


                        <label className="fish_info fish_tank_info fish_label fish_size">
                            <h3>Fish Size</h3>
                            <p>Fish sizing is data taken from collected specimens, and home grown fish.</p>

                            <Input className="small-input" label='length (cm)'
                                   labelPosition='cm' labelPosition='right' alue={fish_size ?? ""}
                                   onChange={(e) => setFish_size(e.target.value)}/>
                        </label>

                        <label className="fish_info fish_tank_info fish_label fish_tank_info">
                            <h3>Aquarium setup</h3>
                            <p>Aquarium sizing is super important to your fish, these are the minimum recommended
                                sizes for a group.</p>

                            <Input className="small-input" label='length (cm)'
                                   labelPosition='right' value={tank_size ?? ""}
                                   onChange={(e) => setTank_size(e.target.value)}/>
                            <Input className="small-input ml-m" label='litres'
                                   labelPosition='right' value={tank_volume ?? ""}
                                   onChange={(e) => setTank_volume(e.target.value)}/>
                        </label>

                        {refs.length > 0 &&
                            <label className="fish_info fish_tank_info fish_label fish_tank_info">
                                <h3>References:</h3>
                                {links()}
                            </label>
                        }


                    </Form>
                </div>

        </div>

    );
};

export default Fish;