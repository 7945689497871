import './App.css';
import {ListBox} from './components/ListBox/';
import {Header} from "./components/Header";
import React, {Component} from 'react';
import {Search} from "./components/Search";
import {Link} from "react-router-dom";

export class Home extends Component {

    render() {
        return (
            <div className="App ">
            <Header/>

                <div className="body grid grid-cols-1 gap-4">
                    <Search/>
                    <div className="text-2xl font-extralight md:col-span-2 pb-12 text-center">
                        Teeny.fish is a novel new search engine for freshwater fish; in it's infancy but will certainly improve over time. 🐡
                    </div>

                    <div className="text-base font-extralight md:col-span-2 pb-12 text-center">
                        See fish missing information <Link to="/missing">here</Link>
                    </div>

                </div>

            </div>
        );
    }
}

export default Home;


