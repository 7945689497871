import React, { Component } from 'react';
import "./header.css";
import logo from "../../images/logo.png";
import edit from "../../images/edit.svg";
import upload from "../../images/upload.svg";


export class Header extends Component {

    render() {
        return (

            <header className="static h-[120px]">

                <div className="flex justify-between max-w-[1200px] m-auto">
                    <div> <a href="/"><img src={logo} className="h-[140px]"/></a> </div>
                    <nav className="menu">
                        <ul>
                            <li><a href="/todo">todo</a></li>
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }
}


export default Header;