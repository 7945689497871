import './App.css';
import {ListBox} from './components/ListBox/';
import {Header} from "./components/Header";
import React, {Component} from 'react';
import {Search} from "./components/Search";
import {Gallery} from "./components/Gallery";

export class Home extends Component {

    render() {
        return (
            <div className="App ">
            <Header/>



                <div className="body grid grid-cols-2 gap-4">
                    <div className="text-4xl font-extralight col-span-2 pb-12">
                        By invitation, journals on this site are to detail fish keeping.
                    </div>

                    <div className="text-xl font-extralight col-span-2 pb-12">
                        If you're looking for a place to document a fish keeping journey, do reach out.
                    </div>

                </div>
            </div>
        );
    }
}

export default Home;


